import { createSlice } from '@reduxjs/toolkit';

const initialDataFilterGraph = {
  valueActiveGraph: "all",
  valueSectionGraph: "all",
  valueTypeTime: "hours"
};

const initialDataFilterGraphDrill = {
  valueTypeTime: "hours"
};

const initialDataFilterGraphTripp = {
  valueTypeTime: "hours"
};

const initialDataFilterBuild = {
  valueType: '2D',
  valueTypeTitle: 'PrML',
  valueTypeGeneral: 'Plan'
};

const initialLimitDomainOverview = {
  GraphicsWellDificulty: { top: 0, bottom: 0 },
};

const initialLimitDomainDrilling = {
  GraphicsPerformance: { top: 0, bottom: 0 },
  GraphicsDirectional: { top: 0, bottom: 0 }
};

const initialLimitDomainTripping = {
  GraphicsPerformance: { top: 0, bottom: 0 },
  GraphicsMetrics: { top: 0, bottom: 0 }
};

const initialLimitsAxisXDrilling = {
  GraphicsPerformance: {
    top: { start: 0, end: 0 },
    rop: { start: 0, end: 0 },
    mserop: { start: 0, end: 0 },
    slpslp: { start: 0, end: 0 },
    wtwt: { start: 0, end: 0 }
  },
  GraphicsDirectional: {
    sldrot: { start: 0, end: 0 },
    inc: { start: 0, end: 0 },
    azm: { start: 0, end: 0 },
    dls: { start: 0, end: 0 },
  }
};

const initialLimitsAxisXOverview = {
  GraphicsWellDificulty: {
    top: { start: 0, end: 0 },
    rop: { start: 0, end: 0 },
    mserop: { start: 0, end: 0 },
    slpslp: { start: 0, end: 0 },
    wtwt: { start: 0, end: 0 }
  },
};

const initialLimitsAxisXTripping = {
  GraphicsPerformance: {
    pipespd: { start: 0, end: 0 },
    pipemovement: { start: 0, end: 0 },
    slpslp: { start: 0, end: 0 }
  },
  GraphicsMetrics: {
    cirstatic: { start: 0, end: 0 },
    washing: { start: 0, end: 0 },
    reaming: { start: 0, end: 0 }
  }
};

const initialState = {
  dataFilterGraph: JSON.parse(sessionStorage.getItem('dataFilterGraph')) || initialDataFilterGraph,
  dataFilterGraphDrill: JSON.parse(sessionStorage.getItem('dataFilterGraphDrill')) || initialDataFilterGraphDrill,
  dataFilterGraphTripp: JSON.parse(sessionStorage.getItem('dataFilterGraphTripp')) || initialDataFilterGraphTripp,
  dataFilterBuild: JSON.parse(sessionStorage.getItem('dataFilterBuild')) || initialDataFilterBuild,
  loadingPage: JSON.parse(sessionStorage.getItem('loadingPage')) || false,
  loadingPageApply: JSON.parse(sessionStorage.getItem('loadingPageApply')) || false,
  invalidatePageRun: JSON.parse(sessionStorage.getItem('invalidatePageRun')) || false,
  validTable: JSON.parse(sessionStorage.getItem('validTable')) || sessionStorage.getItem('validateTable') || false,
  limitsDomainDrilling: JSON.parse(sessionStorage.getItem('limitsDomainDrilling')) || initialLimitDomainDrilling,
  limitsDomainOverview: JSON.parse(sessionStorage.getItem('limitsDomainOverview')) || initialLimitDomainOverview,
  limitsAxisXDrilling: JSON.parse(sessionStorage.getItem('limitsAxisXDrilling')) || initialLimitsAxisXDrilling,
  limitsAxisXOverview: JSON.parse(sessionStorage.getItem('limitsAxisXOverview')) || initialLimitsAxisXOverview,
  limitsDomainTripping: JSON.parse(sessionStorage.getItem('limitsDomainTripping')) || initialLimitDomainTripping,
  limitsAxisXTripping: JSON.parse(sessionStorage.getItem('limitsAxisXTripping')) || initialLimitsAxisXTripping,
  typeWellDifficulty: sessionStorage.getItem('typeWellDifficulty') || 'Depth',
  domainZoomWell: JSON.parse(sessionStorage.getItem('domainZoomWell')) || {}
};

export const filterGraphSlice = createSlice({
  name: 'filterGraph',
  initialState,
  reducers: {
    setDataFilterGraph(state, action) {
      state.dataFilterGraph = action.payload;
    },
    setDataFilterGraphDrill(state, action) {
      state.dataFilterGraphDrill = action.payload;
    },
    setDataFilterGraphTripp(state, action) {
      state.dataFilterGraphTripp = action.payload;
    },
    setDataFilterBuild(state, action) {
      state.dataFilterBuild = action.payload;
    },
    setLoadingPage(state, action) {
      state.loadingPage = action.payload;
    },
    setLoadingPageApply(state, action) {
      state.loadingPageApply = action.payload;
    },
    setInvalidatePageRun(state, action) {
      state.invalidatePageRun = action.payload;
    },
    setValidTable(state, action) {
      state.validTable = action.payload;
    },
    setLimitsDomainDrilling(state, action) {
      state.limitsDomainDrilling = action.payload;
    },
    setLimitsDomainOverview(state, action) {
      state.limitsDomainOverview = action.payload;
    },
    setLimitsAxisXDrilling(state, action) {
      state.limitsAxisXDrilling = action.payload;
    },
    setLimitsAxisXOverview(state, action) {
      state.limitsAxisXOverview = action.payload;
    },
    setLimitsDomainTripping(state, action) {
      state.limitsDomainTripping = action.payload;
    },
    setLimitsAxisXTripping(state, action) {
      state.limitsAxisXTripping = action.payload;
    },
    setTypeWellDifficulty(state, action) {
      state.typeWellDifficulty = action.payload;
    },
    setDomainZoomWell(state, action) {
      state.domainZoomWell = action.payload;
    },
  },
});

export const {
  setDataFilterGraph,
  setDataFilterGraphDrill,
  setDataFilterGraphTripp,
  setDataFilterBuild,
  setLoadingPage,
  setLoadingPageApply,
  setInvalidatePageRun,
  setValidTable,
  setLimitsDomainDrilling,
  setLimitsDomainOverview,
  setLimitsAxisXDrilling,
  setLimitsAxisXOverview,
  setLimitsDomainTripping,
  setLimitsAxisXTripping,
  setTypeWellDifficulty,
  setDomainZoomWell,
} = filterGraphSlice.actions;

export default filterGraphSlice.reducer;
