import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataRealTimeAggregatedTbd: [],
  dataRealTimeAggregatedOs: [],
  runOutputTables: [],
  dataRealtimeRowsOs: [],
  dataRealtimeRowsTbd: [],
  dataStrUnprocessed: [],
  error: null,
  loading: false
};

export const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setDataRealTimeAggregatedTbd: (state, action) => {
      state.dataRealTimeAggregatedTbd = action.payload;
    },
    setDataRealTimeAggregatedOs: (state, action) => {
      state.dataRealTimeAggregatedOs = action.payload;
    },
    setRunOutputTables: (state, action) => {
      state.runOutputTables = action.payload;
    },
    setDataRealtimeRowsOs: (state, action) => {
      state.dataRealtimeRowsOs = action.payload;
    },
    setDataRealtimeRowsTbd: (state, action) => {
      state.dataRealtimeRowsTbd = action.payload;
    },
    setDataStrUnprocessed: (state, action) => {
      state.dataStrUnprocessed = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const {
  setDataRealTimeAggregatedTbd,
  setDataRealTimeAggregatedOs,
  setRunOutputTables,
  setDataRealtimeRowsOs,
  setDataRealtimeRowsTbd,
  setDataStrUnprocessed,
  setLoading,
  setError
} = socketSlice.actions;

export default socketSlice.reducer;