export const TYPE_FIELD = {
	CLIENTS: "user_clientId",
	CLIENT: "client",
	PROJECTS: "proyects",
	PLATFORMS: "platforms"
}
export const TYPE_DATA_GRIDS = {
	DEFAULT_CONFIG:"config_0",
	FIRST_CONFIG: "config_1",
	SECOND_CONFIG: "config_2",
	THIRD_CONFIG: "config_3",
	FOURTH_CONFIG: "config_4",
	FIFTH_CONFIG: "config_5",
	SIXTH_CONFIG: "config_6",
	SEVENTH_CONFIG: "config_7",
	EIGTH_CONFIG: "config_8",
	NINETH_CONFIG: "config_9",
	TENTH_CONFIG: "config_10",
	ELEVENTH_CONFIG: "config_11",
	TWELFTH_CONFIG: "config_12",
	THIRTENTH_CONFIG: "config_13",
	FOURTENTH_CONFIG: "config_14",
	FIVETEENTH_CONFIG: "config_15",
	SIXTENTH_CONFIG: "config_16",
}
export const BRUSH_FIELD = {
	min: "limitMin",
	max: "limitMax",
}

export const BUILD_OPTIONS ={
	plan: 'Plan',
	rt: 'Rt',
	offset: 'Offset',
	prML: 'PrML'
}