import { AppRoutes } from "./routes/AppRoutes";

function AppDashboard() {

  return (
    <>
      <AppRoutes></AppRoutes>
    </>
  );
}

export default AppDashboard;
