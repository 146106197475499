import { createSlice } from '@reduxjs/toolkit';
import { TYPE_GENERAL_DATA } from '../../components/elements/types/type_general_data';

const initialState = {
    dataConfigTab: {},
    dataTab: sessionStorage.getItem('dataTab') || 'dark',
    mode: sessionStorage.getItem('mode') || 'dark',
    generalData: JSON.parse(sessionStorage.getItem('generalData')) ||  TYPE_GENERAL_DATA
};

export const configTabSlice = createSlice({
    name: 'configTab',
    initialState,
    reducers: {
        setDataConfigTab: (state, action) => {
            state.dataConfigTab = action.payload;
        },
        setDataTab: (state, action) => {
            state.dataTab = action.payload;
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setGeneralData: (state, action) => {
            state.generalData = action.payload
        }
    },
});

export const { setDataConfigTab, setDataTab, setMode, setGeneralData } = configTabSlice.actions;
export default configTabSlice.reducer;