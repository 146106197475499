import CryptoJS from "crypto-js";
import { config, keyIntegration } from "../../config/config";

const PORT_DB = config.API.BASE_URL_ACCESS_CONTROL;
const privateKey = keyIntegration.keyEncrypt;

export const loginUser = async (user_data) => {
  const hash = CryptoJS.SHA256(privateKey);
  const hashHex = hash.toString(CryptoJS.enc.Hex);
  const extendedKey = hashHex.padEnd(64, '0').substring(0, 64);
  const key = CryptoJS.enc.Hex.parse(extendedKey);
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encryptedPassword = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(user_data.user_password), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  const ivString = CryptoJS.enc.Hex.stringify(iv);
  const payloadPassword = `${ivString}:${encryptedPassword}`;

  let response = await fetch(`${PORT_DB}/signin`, {
    method: "POST",
    body: JSON.stringify({ ...user_data, user_password: payloadPassword }),
    headers: { "Content-Type": "application/json" },
  });
  return response.json();
};

export const forgotPassword = async (email) => {
  let response = await fetch(`${PORT_DB}/ForgotPassword/Validate/${email}`, {
    method: "GET"
  });
  return response.json();
};

export const sendEmailRecovery = async (data) => {
  let response = await fetch(`${PORT_DB}/ForgotPassword/SendEmail`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  });
  return response.json();
};

export const newPassword = async (data, token) => {
  let response = await fetch(`${PORT_DB}/ForgotPassword/NewPassword`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "reset": token
    },
  });
  return response.json();
};

export const validateTokenNewPassword = async (token) => {
  let response = await fetch(`${PORT_DB}/ForgotPassword/ValidateJwt`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "reset": token
    },
  });
  return response.json();
};

export const addAccount = async (data) => {
  let response = await fetch(`${PORT_DB}/ForgotPassword/NewPassword`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Token": ""
    },
  });
  return response.json();
};