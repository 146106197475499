import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectUser: null,
    selectType: "",
    selectTable: ""
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setSelectUser: (state, action) => {
            state.selectUser = action.payload;
        },
        setSelectType: (state, action) => {
            state.selectType = action.payload;
        },
        setSelectTable: (state, action) => {
            state.selectTable = action.payload;
        },
    },
});

export const { setSelectUser, setSelectType, setSelectTable } = userSlice.actions;
export default userSlice.reducer;
