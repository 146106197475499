import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dataBurlRt: JSON.parse(sessionStorage.getItem('dataBurlRt')) || false,
    dataBurlPlan: JSON.parse(sessionStorage.getItem('dataBurlPlan')) || false,
    dataBurlOffset: JSON.parse(sessionStorage.getItem('dataBurlOffset')) || false,
    dataPerformanceRt: JSON.parse(sessionStorage.getItem('dataPerformanceRt')) || false,
    dataRowsRealTime: JSON.parse(sessionStorage.getItem('dataRowsRealTime')) || {
        osRows: 0, 
        tbdRows: 0,
    },
    dataXls:JSON.parse(sessionStorage.getItem('dataXls')) || {},
    enabledModalGraph: sessionStorage.getItem('enabledModalGraph') || false,
};

export const graphSlice = createSlice({
    name: 'graph',
    initialState,
    reducers: {
        setDataBurlRt(state, action) {
            state.dataBurlRt = action.payload;
        },
        setDataBurlPlan(state, action) {
            state.dataBurlPlan = action.payload;
        },
        setDataBurlOffset(state, action) {
            state.dataBurlPlan = action.payload;
        },
        setDataPerformanceRt(state, action) {
            state.dataPerformanceRt = action.payload;
        },
        setDataRowsRealTime(state, action) {
            state.dataRowsRealTime = action.payload;
        },
        setDataXls(state, action) {
            state.dataXls = action.payload;
        },
        setEnabledModalGraph(state, action) {
            state.enabledModalGraph = action.payload;
        },
        removeDataDirectionalRt(state) {
            state.dataDirectionalRt = false;
        },
        removeDataPerformanceRt(state) {
            state.dataPerformanceRt = false;
        },
    },
});

export const {
    setDataBurlRt,
    setDataBurlPlan,
    setDataBurlOffset,
    setDataPerformanceRt,
    setDataRowsRealTime,
    setDataXls,
    setEnabledModalGraph,
    removeDataDirectionalRt,
    removeDataPerformanceRt,
} = graphSlice.actions;

export default graphSlice.reducer;
