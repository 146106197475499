import CryptoJS from "crypto-js";
import { keyIntegration } from "../config/config";

export const encriptKey = (data) => {
    var textEncript = CryptoJS.AES.encrypt(data, keyIntegration.keyEncrypt).toString();
    return textEncript;
  }

export  const decryptData = () => {
    return null;
}