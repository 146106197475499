import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dataSelectSurveys: [],
    updatedData: []
};

export const buildTabSlice = createSlice({
    name: 'buildTab',
    initialState,
    reducers: {
        setDataSelectSurveys: (state, action) => {
            state.dataSelectSurveys = action.payload;
        },
        setUpdatedData: (state, action) => {
            state.updatedData = action.payload;
        },

    },
});

export const { 
    setDataSelectSurveys,
    setUpdatedData
} = buildTabSlice.actions;
export default buildTabSlice.reducer;