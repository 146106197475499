import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalTabInfo: JSON.parse(sessionStorage.getItem('modalTabInfo')) || '',
    valueTableModal: JSON.parse(sessionStorage.getItem('valueTableModal')) || '',
};

export const modalTabSlice = createSlice({
    name: 'modalTab',
    initialState,
    reducers: {
        setDataInfoModal: (state, action) => {
            state.modalTabInfo = action.payload;
        },
        setValueTableModal: (state, action) => {
            state.valueTableModal = action.payload;
        }
    },
});

export const {
    setDataInfoModal,
    setValueTableModal
} = modalTabSlice.actions;

export default modalTabSlice.reducer;