import { createSlice } from '@reduxjs/toolkit';
import { TYPE_DATA_GRIDS } from '../../components/elements/types/type_field';

const initialDatadGrid = TYPE_DATA_GRIDS.DEFAULT_CONFIG;

const initialState = {
    dataGrids: JSON.parse(sessionStorage.getItem('dataGrid')) || initialDatadGrid,
    selectedGraph: JSON.parse(sessionStorage.getItem('SelectedGraph')) || [],
    dataGridsDrilling: JSON.parse(sessionStorage.getItem('dataGridDrill')) || initialDatadGrid,
    selectedGraphDrilling: JSON.parse(sessionStorage.getItem('SelectedGraphDrill')) || '',
    dataGridsTripping: JSON.parse(sessionStorage.getItem('dataGridTripp')) ||initialDatadGrid,
    selectedGraphTripping: JSON.parse(sessionStorage.getItem('SelectedGraphTripp')) || [],
    dataGridsBuild: JSON.parse(sessionStorage.getItem('dataGridBuild')) || initialDatadGrid,
    selectedGraphBuild: JSON.parse(sessionStorage.getItem('SelectedGraphBuild')) || [],
};

export const gridSlice = createSlice({
    name: 'grid',
    initialState,
    reducers: {
        setDataGrids(state, action) {
            state.dataGrids = action.payload;
        },
        setDataGraphSelected(state, action) {
            state.selectedGraph = action.payload;
        },
        setDataGridDrill(state, action) {
            state.dataGridsDrilling = action.payload;
        },
        setDataGraphSelectedDrill(state, action) {
            state.selectedGraphDrilling = action.payload;
        },
        setDataGridTripp(state, action) {
            state.dataGridsTripping = action.payload;
        },
        setDataGraphSelectedTripp(state, action) {
            state.selectedGraphTripping = action.payload;
        },
        setDataGridBuild(state, action) {
            state.dataGridsBuild = action.payload;
        },
        setDataGraphSelectedBuild(state, action) {
            state.selectedGraphBuild = action.payload;
        },
    },
});

export const {
    setDataGrids,
    setDataGraphSelected,
    setDataGridDrill,
    setDataGraphSelectedDrill,
    setDataGridTripp,
    setDataGraphSelectedTripp,
    setDataGridBuild,
    setDataGraphSelectedBuild
} = gridSlice.actions;

export default gridSlice.reducer;
