import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/hooks/useAuth";
import { useState } from 'react';

export const useTokenExpiration = () => {

    const {signout} = useAuth();
    const navigate = useNavigate();
    const [showModalExpirated, setShowModalExpirated] = useState(false);

    const performLogout = () => {
      // Acciones de cierre de sesion
      navigate("/login");
      setTimeout(() => {
          signout();
      }, 10000);
    }

    const showWarningMessage = () => {
        // Aquí puedes mostrar un mensaje de advertencia al usuario
        alert('Your session will expire soon.Please keep your job.');
        
      };
    
    const   checkTokenExpiration = () => {
      const dataUser = JSON.parse(sessionStorage.getItem("userData"))
      const token = dataUser?.token
      if (token) {
        const decodedToken = jwt_decode(token); // Decodifica el token
        const currentTime = Date.now() / 1000; // Obtén el tiempo actual en segundos
        if (decodedToken.exp < currentTime) {
          // Token expirado, realizar cierre de sesión u otras acciones
          //showWarningMessage();
          setShowModalExpirated(true);
          performLogout();
        } else {
          // Token aún válido, configura un temporizador para verificar la expiración
          const timeUntilExpiry = decodedToken.exp - currentTime;

          const timeToShowWarning = Math.max(0, timeUntilExpiry - 10); // Mostrar la advertencia al menos 10 segundos antes
          setTimeout(()=>{
            showWarningMessage();
          } , timeToShowWarning * 1000);

          setTimeout(() => {
              performLogout();
          }, timeUntilExpiry * 1000);
        }
      }
    }

  return {
    showModalExpirated,
    checkTokenExpiration
  }
}
