import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCookie } from "../../utils/saveCookies";
import { useDispatch } from "react-redux";
import { setAuth } from "../feature/configAuth/configAuthAction";

export const useAuth = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const signOut = (e) => {
    dispatch(setAuth(false));
    Navigate("/Login");
    deleteCookie("infopass");
    sessionStorage.clear();
  };
  return {
    signOut
  }
};