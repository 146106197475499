import Cookies from "universal-cookie";
import { encriptKey } from "./encodeData";

const  cookies = new Cookies(); 
// Función para guardar un valor en una cookie que expira en 10 minutos
export const saveToCookie = (dataSave) => {
    const data = { cookie: dataSave }; // El dato que deseas guardar
    const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000); // Calcula el tiempo de expiración (10 minutos en milisegundos)
    cookies.set('data', data, { expires: expirationTime }); // Guarda el dato en una cookie con tiempo de expiración
}

export const saveCredentialsCookie = (dataUser,dataKey) => {
  const dataKeyEncript = encriptKey(dataKey);
  const dataCredencial = { 
    user: dataUser,
    key: dataKeyEncript
  }; 
  const data = JSON.stringify(dataCredencial);
  const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000); 
  cookies.set('infopass', data, { expires: expirationTime }); 
}

export const getFromCookie = (value) => {
    return cookies.get(value); // Recupera el dato de la cookie 'myCookieName'
}

export const deleteCookie = (cookieName) => {
    cookies.remove(cookieName);
}