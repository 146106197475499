import { combineReducers } from "@reduxjs/toolkit";
import configTabReducer from "../configTab/configTabSlice";
import configAuthReducer from "../../auth/feature/configAuth/configAuthSlice";
import userReducer from "../user/userSlice";
import filterReducer from "../filter/filterSlice";
import socketReducer from "../socket/socketSlice";
import filterGraphReducer from "../filterGraph/filterGraphSlice";
import gridReducer from "../grid/gridSlice";
import graphReducer from "../graph/graphSlice";
import buildTabReducer from "../buildTab/buildTabSlice";
import modalTabReducer  from "../configModal/configModalSlice";

const rootReducers = combineReducers({
    configTab: configTabReducer,
    user: userReducer,
    configAuth: configAuthReducer,
    filter: filterReducer,
    socket: socketReducer,
    filterGraph: filterGraphReducer,
    grid: gridReducer,
    graph: graphReducer,
    buildTab : buildTabReducer,
    modalTabInfo: modalTabReducer
})

export default rootReducers;