export const config = {
    LINK : {
        BASE_URL_UPLOADFILE: process.env.REACT_APP_LINK_UPLOADFILE,
        B4E: process.env.REACT_APP_LINK_B4E,
        PRODUCTION_ANALYTICS: process.env.REACT_APP_LINK_PRODUCTION_ANALYTICS,
        DRILLING_OPTIMIZATION: process.env.REACT_APP_LINK_DRILLING_OPTIMIZATION,
    },
    API: {
        BASE_URL_ACCESS_CONTROL : process.env.REACT_APP_PORT_DB,
        BASE_URL_FILTER : process.env.REACT_APP_BASE_URL_EXTDATA,
        BASE_URL_TABLES: process.env.REACT_APP_BASE_URL_TABLES,
        BASE_URL_WELL : process.env.REACT_APP_BASE_URL_EXTDATA,
        BASE_RUN_WELL : process.env.REACT_APP_BASE_RUN,
        BASE_URL_STREAM : process.env.REACT_APP_BASE_URL_STREAMING,
        BASE_URL_STREAM_TABLES : process.env.REACT_APP_BASE_URL_STREAMING,
        BASE_URL_STREAM_DATA : process.env.REACT_APP_BASE_URL_STREAMING,
        BASE_URL_STREAM_DEPTH : process.env.REACT_APP_BASE_URL_STREAMING,
        BASE_URL_RUN_API : process.env.REACT_APP_BASE_RUN,
        BASE_URL_SCENARIO : process.env.REACT_APP_BASE_URL_SCENARIO,
        BASE_URL_CACHE: process.env.REACT_APP_BASE_URL_CACHE,
        BASE_URL_SOCKET: process.env.REACT_APP_BASE_URL_SOCKETS,
        BASE_URL_BUILD_UP_RATE: process.env.REACT_APP_BASE_URL_BUILD_UP_RATE,
        BASE_URL_PARAMETERS: process.env.REACT_APP_BASE_URL_PARAMETERS,
    },
    AUTH: {
        TOKEN_FILTER : process.env.REACT_APP_TOKEN_EXTDATA,
        TOKEN_TABLES: process.env.REACT_APP_TOKEN_OUTPUTDATA,
        TOKEN_WELL : process.env.REACT_APP_TOKEN_EXTDATA,
        TOKEN_RUN_WELL : process.env.REACT_APP_TOKEN_RUN_WELL,
        TOKEN_STREAM : process.env.REACT_APP_TOKEN_STREAM,
        TOKEN_STREAM_TABLES : process.env.REACT_APP_TOKEN_STREAM,
        TOKEN_STREAM_DATA : process.env.REACT_APP_TOKEN_STREAM,
        TOKEN_STREAM_DEPTH : process.env.REACT_APP_TOKEN_STREAM,
        TOKEN_RUN_API : process.env.REACT_APP_TOKEN_RUN_WELL,
        TOKEN_SCENARIO : process.env.REACT_APP_TOKEN_SCENARIO,
        TOKEN_CACHE : process.env.REACT_APP_TOKEN_CACHE,
        TOKEN_SOCKET: process.env.REACT_APP_TOKEN_SOCKET,
        TOKEN_BUILD_UP_RATE: process.env.REACT_APP_TOKEN_BUILD_UP_RATE,
        TOKEN_PARAMETERS: process.env.REACT_APP_TOKEN_PARAMETERS,
    }
}

export const keyIntegration = {
    keyEncrypt : process.env.REACT_APP_KEYENCRYPT,
}