import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppDashboard from "./AppDashboard";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import 'react-tooltip/dist/react-tooltip.css'
import Modal from 'react-modal';
import { store } from "./store/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
// Establish the application element for react-modal
Modal.setAppElement('#root');
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppDashboard />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
