import React from "react";
import { useSelector } from "react-redux";
import Logo from "../assets/image/Logo.png";
export const Loading = () => {
    const {  dataTab } = useSelector(state => state.configTab);
    const { invalidatePageRun } = useSelector(state => state.filterGraph);

    return (
        <div className={`${dataTab === 'tab1' && !invalidatePageRun ? "bg-nc-gray-bg grid-cols-2" : " bg-nc-gray-bg"}`}>
            <div className={`${dataTab === 'tab1' && !invalidatePageRun ? 'h-full cols-span-2' : 'h-screen'} text-center justify-center items-center flex flex-col`}>
                <div className="flex text-center justify-center items-center">
                    <img
                        src={Logo}
                        className="w-20 h-20 m-2 animate-spin"
                        alt=""
                        loading="loading" />
                </div>
                <div>
                    <h1 className="font-sans m-2 text-4xl bg-gradient-to-r from-pc-purple to-pc-aqua-2 text-transparent bg-clip-text animate-bounce ">LOADING</h1>
                </div>
            </div>
        </div>
    );
};
