import { createSlice } from '@reduxjs/toolkit';
import { signIn } from './configAuthAction';

const initialState = {
    auth: sessionStorage.getItem('auth') || false  ,
    userData: JSON.parse(sessionStorage.getItem('userData')) || null,
    loading: null,
    error: null
};

export const configAuthSlice = createSlice({
    name: 'configAuth',
    initialState,
    extraReducers:(builder) => {
        builder
        .addCase(signIn.pending,(state) => {
            state.auth = false;
            state.loading = true; 
            state.userData = null; 
            state.error = null;
        })
        .addCase(signIn.fulfilled,(state,action) => {
            state.auth = true;
            state.loading = false; 
            state.userData = action.payload; 
            state.error = null;
        })
        .addCase(signIn.rejected,(state,action) => {
            state.auth = false;
            state.loading = false; 
            state.userData = []; 
            state.error = action.payload || 'An error occurred';
        })
    },
});

export const { setAuth, setUserData } = configAuthSlice.actions;
export default configAuthSlice.reducer;