import React, { lazy, Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes } from "react-router-dom";
import { Loading } from '../components/Loading';
import ProtectedRoute from '../components/ProtectedRoute';
import { useTokenExpiration } from '../hooks/useTokenExpiration';

// Lazy loading components
const CrudInternal = lazy(() => import('../pages/Users_Control/crudInternal').then(module => ({ default: module.CrudInternal })));
const CrudExternal = lazy(() => import('../pages/Users_Control/crudExternal').then(module => ({ default: module.CrudExternal })));
const Register = lazy(() => import('../pages/Users_Control/Register').then(module => ({ default: module.Register })));
const Login = lazy(() => import('../auth/pages/Login').then(module => ({ default: module.Login })));
const Home = lazy(() => import('../pages/Home/Home').then(module => ({ default: module.Home })));
const HomeAdmin = lazy(() => import('../pages/Home/HomeAdmin').then(module => ({ default: module.HomeAdmin })));
const Tab1 = lazy(() => import('../pages/Tab1/Tab1').then(module => ({ default: module.Tab1 })));
const Tab2 = lazy(() => import('../pages/Tab2/Tab2').then(module => ({ default: module.Tab2 })));
const Tab3 = lazy(() => import('../pages/Tab3/Tab3').then(module => ({ default: module.Tab3 })));
const Tab4 = lazy(() => import('../pages/Tab4/Tab4').then(module => ({ default: module.Tab4 })));
const Tab5 = lazy(() => import('../components/Tab5').then(module => ({ default: module.Tab5 })));
const NotFound = lazy(() => import('../components/elements/NotFound').then(module => ({ default: module.NotFound })));
const TokenExpired = lazy(() => import('../components/TokenExpired').then(module => ({ default: module.TokenExpired })));
const ForgotPassword = lazy(() => import('../auth/pages/ForgotPassword').then(module => ({ default: module.ForgotPassword })));
const AddAccount = lazy(() => import('../auth/pages/AddAccount').then(module => ({ default: module.AddAccount })));
const CreateNewPassword = lazy(() => import('../auth/pages/CreateNewPassword').then(module => ({ default: module.CreateNewPassword })));
const DrillOptimization = lazy(() => import('../pages/Optimization/DrillOptimization').then(module => ({ default: module.DrillOptimization })));
const UploadFiles = lazy(() => import('../pages/UploadFiles/UploadFiles').then(module => ({ default: module.UploadFiles })));
const StreamingAdmin = lazy(() => import('../pages/Streaming/StreamingAdmin').then(module => ({ default: module.StreamingAdmin })));
const BuildUpRate = lazy(() => import('../pages/BuildUpRate/BuildUpRate').then(module => ({ default: module.default })));

const queryClient = new QueryClient();
export { queryClient };

export const AppRoutes = () => {
  // Proceso para validar token de autenticacion
  const { checkTokenExpiration, showModalExpirated } = useTokenExpiration();

  useEffect(() => {
    checkTokenExpiration();
  }, [showModalExpirated]);

  return (
    <div className={`bg-slate-600 text-black`}>
      <QueryClientProvider client={queryClient}>
        {showModalExpirated && <TokenExpired />}
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route element={<ProtectedRoute isAllowed="External" />}>
              <Route path="/" element={<Home />} />
              <Route index element={<Home />} />
            </Route>
            <Route element={<ProtectedRoute isAllowed="Internal" />}>
              <Route path="/Admin" element={<HomeAdmin />} />
              <Route path="/register" element={<Register />} />
              <Route path="/crud/external" element={<CrudExternal />} />
              <Route path="/crud/internal" element={<CrudInternal />} />
            </Route>
            <Route element={<ProtectedRoute isAllowed="Bound" />}>
              <Route path="/tab1" element={<Tab1 />} />
              <Route path="/tab2" element={<Tab2 />} />
              <Route path="/tab3" element={<Tab3 />} />
              <Route path="/tab4" element={<Tab4 />} />
              <Route path="/tab5" element={<Tab5 />} />
              <Route path="/admin-portal-streaming" element={<StreamingAdmin />} />
              <Route path="/drill-optimization-service" element={<DrillOptimization />} />
              <Route path="/upload-files-service" element={<UploadFiles />} />
              <Route path="/build-up-rate" element={<BuildUpRate />} />
            </Route>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/add-account" element={<AddAccount />} />
            <Route path="/new-password" element={<CreateNewPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </QueryClientProvider>
    </div>
  );
};

export default AppRoutes;
