import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataFilter: JSON.parse(sessionStorage.getItem('dataFilter')) || {},
  dataRun: sessionStorage.getItem('dataRun') || [],
  dataInfo: JSON.parse(sessionStorage.getItem('dataScenarioInfo')) || {},
  dataScenario:  JSON.parse(sessionStorage.getItem('dataScenario')) || {},
  indexScenario: sessionStorage.getItem('indexScenarioInfo') || null,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setDataFilter: (state, action) => {
      state.dataFilter = action.payload;
    },
    setDataRun: (state, action) => {
      state.dataRun = action.payload;
    },
    setDataInfo: (state, action) => {
      state.dataInfo = action.payload;
    },
    setDataScenario: (state, action) => {
      state.dataScenario = action.payload;
    },
    setIndexScenario: (state, action) => {
      state.indexScenario = action.payload;
    },
  },
});

export const {
  setDataFilter,
  setDataRun,
  setDataInfo,
  setDataScenario,
  setIndexScenario,
} = filterSlice.actions;

export default filterSlice.reducer;