import { TYPE_DATA_GRIDS } from "./type_field";


export const TYPE_GENERAL_DATA = {
    mode: "",
    streaming: "",
    project: "",
    client: "",
    scenario: "",
    zoomTimeDepth: [0, 0],
    zoomWellDifficulty: [0, 0],
    SelectedGraph: [
        { btn: 1, enabled: false },
        { btn: 2, enabled: false },
        { btn: 3, enabled: false },
        { btn: 4, enabled: false },
        { btn: 5, enabled: false }
    ],
    dataGrid: TYPE_DATA_GRIDS.DEFAULT_CONFIG,
    SelectedTypeWellDifficulty: "Depth",
    dataFilterGraph: {
        valueActiveGraph: "all",
        valueSectionGraph: "all",
        valueTypeTime: "hours"
    },
    SelectedGraphDrill: [
        { btn: 1, enabled: false },
        { btn: 2, enabled: false },
        { btn: 3, enabled: false },
        { btn: 4, enabled: false }
    ],
    dataGridDrill: TYPE_DATA_GRIDS.DEFAULT_CONFIG,
    dataFilterGraphDrill: {
        valueTypeTime: "hours"
    },
    limitsDomainDrilling: {
        GraphicsDirectional: { top: 0, bottom: 100 },
        GraphicsPerformance: { top: 0, bottom: 100 }
    },
    limitsAxisXDrilling: {
        GraphicsDirectional: {
            azm: { start: 0, end: 0 },
            dls: { start: 0, end: 0 },
            inc: { start: 0, end: 0 }
        },
        GraphicsPerformance: {
            mserop: { start: 0, end: 0 },
            rop: { start: 0, end: 0 },
            slpslp: { start: 0, end: 0 },
            top: { start: 0, end: 0 },
            wtwt: { start: 0, end: 0 }
        }
    },
    SelectedGraphTripp: [
        { btn: 1, enabled: false },
        { btn: 2, enabled: false },
        { btn: 3, enabled: false },
        { btn: 4, enabled: false }
    ],
    dataGridTripp: TYPE_DATA_GRIDS.DEFAULT_CONFIG,
    dataFilterGraphTripp: {
        valueTypeTime: "hours"
    },
    limitsDomainTripping: {
        GraphicsPerformance: { top: 0, bottom: 0 },
        GraphicsActivity: { top: 0, bottom: 0 }
    },
    limitsAxisXTripping: {
        GraphicsActivity: {
            cirstatic: { start: 0, end: 0 },
            reaming: { start: 0, end: 0 },
            washing: { start: 0, end: 0 }
        },
        GraphicsPerformance: {
            pipemovement: { start: 0, end: 0 },
            pipespd: { start: 0, end: 0 },
            slpslp: { start: 0, end: 0 }
        }
    },
    SelectedGraphBuild: [
        { btn: 1, enabled: false },
        { btn: 2, enabled: false },
        { btn: 3, enabled: false }
    ],
    dataGridBuild: TYPE_DATA_GRIDS.DEFAULT_CONFIG,
    dataFilterBuild: {
        valueTypeGeneral: "Rt",
        valueTypeTitle: "Rt"
    },
    targetDirection: '',
    targetDirectionPlan: '',
    targetDirectionOffset: '',
    burOption: '',
    inputBur: '',
    burDegSSValue: '',
    ftToSlideValue: '',
    measuredDepthStart: '',
    predictedBur: '',
    feetToSlidePrML: '',
    measuredDepthEnd: '',
    gradeToBuild: '',
    selectedBurRows: [],
    selectedWell: ''
};
